import React, { memo, useMemo } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { DefaultSettingsAdapter } from '../DefaultSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

export const WidgetContext: React.FC = memo(({ children }) => {
  const settings = useSettings();
  const settingsAdapter = useMemo(() => new DefaultSettingsAdapter(settings, settingsParams), [settings]);
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <SettingsAdapterProvider adapter={settingsAdapter}>{children}</SettingsAdapterProvider>
    </StylesParamsProvider>
  );
});
